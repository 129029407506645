// VideoGenerationPage.js
import React from 'react';
import VideoGallery from './VideoGallery';

// Массив відео
const videos = [
  { title: "Video 1", url: "https://www.youtube.com/watch?v=zrRHG2QAExA", thumbnail: "https://img.youtube.com/vi/ysz5S6PUM-U/hqdefault.jpg" },
  { title: "Video 2", url: "https://www.youtube.com/watch?v=zrRHG2QAExA", thumbnail: "https://img.youtube.com/vi/dQw4w9WgXcQ/hqdefault.jpg" },
  { title: "Video 3", url: "https://www.youtube.com/watch?v=zrRHG2QAExA", thumbnail: "https://i.vimeocdn.com/video/595198868_640.jpg" },
  { title: "Video 4", url: "https://www.youtube.com/watch?v=ysz5S6PUM-U" }, // No thumbnail

  { title: "Video 5", url: "https://www.youtube.com/watch?v=zrRHG2QAExA", thumbnail: "https://img.youtube.com/vi/ysz5S6PUM-U/hqdefault.jpg" },
  { title: "Video 6", url: "https://www.youtube.com/watch?v=zrRHG2QAExA", thumbnail: "https://img.youtube.com/vi/dQw4w9WgXcQ/hqdefault.jpg" },
  { title: "Video 7", url: "https://www.youtube.com/watch?v=zrRHG2QAExA", thumbnail: "https://i.vimeocdn.com/video/595198868_640.jpg" },
  { title: "Video 8", url: "https://www.youtube.com/watch?v=ysz5S6PUM-U" }, // No thumbnail
];

const VideoGenerationPageTest = () => {
  return (
    <div>
      <h1>Video Generation Page</h1>
      <VideoGallery videos={videos} />
    </div>
  );
};

export default VideoGenerationPageTest;
