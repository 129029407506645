// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomeStyle_generation-container__3IfXl {
    display: flex;
    flex-direction: column;
    height: auto;
    background: linear-gradient(180deg, #0420ae 0%, #1aff00 100%)!important;
    color: white;
    font-family: 'Arial', sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/components/home/HomeStyle.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,uEAAuE;IACvE,YAAY;IACZ,gCAAgC;AACpC","sourcesContent":[".generation-container {\n    display: flex;\n    flex-direction: column;\n    height: auto;\n    background: linear-gradient(180deg, #0420ae 0%, #1aff00 100%)!important;\n    color: white;\n    font-family: 'Arial', sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generation-container": `HomeStyle_generation-container__3IfXl`
};
export default ___CSS_LOADER_EXPORT___;
