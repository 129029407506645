import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Використання useNavigate для перенаправлення
import userImage from '../img/avatar.svg';
import homeImage from '../img/home.svg';
import signOutImage from '../img/sign_out.svg';
import popularImage from '../img/popular.svg';
import askImage from '../img/ask.svg';
import searchImage from './img/search.svg';
import burgerIcon from '../img/swap.svg'; // Іконка бургер-меню
import './Sidebar.css';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false); // Стан для відкриття/закриття меню
  const navigate = useNavigate();
  const [token, setToken] = useState(null); // Стан для зберігання токену користувача

    // Перевірка токену при завантаженні компонента
  useEffect(() => {
    const storedToken = localStorage.getItem('token'); // Отримуємо токен із localStorage
    setToken(storedToken); // Встановлюємо токен у стан
  }, []);
  const handleSignOut = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

    const handleLogIn = () => {
    navigate('/login');
  };
  const toggleMenu = () => {
    setIsOpen(!isOpen); // Перемикання відкриття меню
  };

  return (
    <>
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <img src={burgerIcon} alt="Menu" className="burger-icon" onClick={toggleMenu} /> {/* Прикріплена бургер-іконка */}
        <div className="user-profile">
          <img src={userImage} alt="User" className="user-avatar" />
          <div className="user-info">
            <span>YOU</span>
          </div>
        </div>
        <nav>
          <Link to="/home" onClick={toggleMenu}>
            <button className="nav-button">
              <img src={homeImage} alt="Home" className="icon" />
              HOME
            </button>
          </Link>
          <Link to="/popular" onClick={toggleMenu}>
            <button className="nav-button">
              <img src={popularImage} alt="Popular" className="icon" />
              POPULAR
            </button>
          </Link>
          <Link to="/ask" onClick={toggleMenu}>
            <button className="nav-button">
              <img src={askImage} alt="Ask" className="icon" />
              ASK
            </button>
          </Link>
          <Link to="/search" onClick={toggleMenu}>
            <button className="nav-button">
              <img src={searchImage} alt="Search" className="icon" />
              SEARCH
            </button>
          </Link>
        </nav>
        {token ? (
        <button className="sign-out" onClick={handleSignOut}>
          <img src={signOutImage} alt="Sign Out" className="icon" />
          SIGN OUT
        </button> ) : (
            <button className="sign-out" onClick={handleLogIn}>
              <img src={signOutImage} alt="Sign Out" className="icon"/>
              Log In
            </button>
        )}
      </div>

      {/* Напівпрозорий фон при відкритому меню */}
      {isOpen && <div className="overlay" onClick={toggleMenu}></div>}
    </>
  );
};

export default Sidebar;
