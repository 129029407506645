import React from 'react';
import styles from './About.module.css'; // Імпортуємо CSS як модуль
import Sidebar from '../Sidebar/Sidebar'; // Імпортуємо Sidebar
import { useNavigate } from 'react-router-dom'; // Імпортуємо useNavigate для переходу

const About = () => {
    const navigate = useNavigate(); // Ініціалізація useNavigate
    const handleAskClick = () => {
    navigate('/ask'); // Переходить на сторінку /ask
  };
  return (
    <div className={styles.generationContainer}>
      <Sidebar /> {/* Використовуємо Sidebar */}
      <div className={styles.aboutContent}>
        <div className={styles.aboutBox}>
          <div className={styles.header}>
            <h1>Mahaon</h1>
            <h2>ABOUT US</h2>
          </div>
          <p>
            Is an interactive app for kids that helps little explorers get answers to their most interesting questions.
          </p>
          <p>
            We create engaging video answers so that children can learn in a fun and understandable way.
          </p>
          <p>
            Mahaon turns learning about the world into a fun adventure, encouraging curiosity and creativity.
          </p>
          <p>
            Together we open up endless horizons of knowledge for future generations!
          </p>
          <button className={styles.askButton} onClick={handleAskClick}>PRESS FOR ASK SOMETHING!</button>
        </div>
      </div>
    </div>
  );
};

export default About;
