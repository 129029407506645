import React, { useState, useEffect } from 'react';
import './Home.css';
import './HomeStyle.module.css';
import Sidebar from '../Sidebar/Sidebar'; // Імпортуй Sidebar
import axios from 'axios'; // Додаємо axios для запиту
import BASE_URL from '../config'; // Імпортуємо домен
import { useNavigate } from 'react-router-dom'; // Використання для перенаправлення

const Generation = () => {
  const [videos, setVideos] = useState([]); // Стан для збереження відео
  const [isLoading, setIsLoading] = useState(true); // Стан для відстеження завантаження
  const [selectedCategory, setSelectedCategory] = useState('Category 1'); // Стан для вибору категорії
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token'); // Отримуємо токен із localStorage
    if (!token) {
      navigate('/login'); // Якщо токену немає, перенаправляємо на сторінку логіну
    }
  }, [navigate]); // Викликаємо ефект лише при зміні функції navigate
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const token = localStorage.getItem('token'); // Отримуємо токен
        const response = await axios.get(`${BASE_URL}/api/user/prompts`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const fetchedVideos = response.data.map((video) => ({
          id: video.id || 'nill', // Перевірка на наявність поля
          title: video.title || 'nill',
          videoUrl: video.video || 'nill', // Посилання на відео
          videoText: video.text || 'nill', // Текст до відео
          thumbnail: video.thumbnail_url || 'nill',
          views: video.views || 'nill',
          time_ago: video.time_ago || 'nill',
          category: video.category || 'Category 1', // Додаємо категорію
        }));

        setVideos(fetchedVideos);
      } catch (error) {
        console.error('Error fetching videos:', error);
      } finally {
        setIsLoading(false); // Вимкнути спіннер після завантаження
      }
    };

    fetchVideos(); // Виклик функції при завантаженні компонента
  }, []);

  // Функція для переходу відео в повноекранний режим
  const handleFullScreen = (videoId) => {
    const videoElement = document.getElementById(`video-${videoId}`);
    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen(); // Для браузерів, що підтримують стандартний Fullscreen API
    } else if (videoElement.mozRequestFullScreen) { /* Firefox */
      videoElement.mozRequestFullScreen();
    } else if (videoElement.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      videoElement.webkitRequestFullscreen();
    } else if (videoElement.msRequestFullscreen) { /* IE/Edge */
      videoElement.msRequestFullscreen();
    }
  };

  // Фільтрація відео по категорії
  const filteredVideos = videos.filter(video => video.category === selectedCategory);

  return (
    <div className="generation-container" style={{ background: 'linear-gradient(rgb(74, 222, 155) 0%, rgb(42 126 88) 100%)' }}>
      <Sidebar /> {/* Використовуй Sidebar як окремий компонент */}
      <main className="main-content">
        {/*<h2>My Generations</h2>*/}

        {/* Вибір категорій */}
        <div className="category-selector">
          <button
            className={selectedCategory === 'Category 1' ? 'active' : ''}
            onClick={() => setSelectedCategory('Category 1')}
          >
            My Generations
          </button>
          <button
            className={selectedCategory === 'Category 2' ? 'active' : ''}
            onClick={() => setSelectedCategory('Category 2')}
          >
            Popular
          </button>
        </div>

        {isLoading ? (
          <div className="loading-spinner">Loading...</div> // Спіннер під час завантаження
        ) : (
          <div className="video-slider-vertical">
            {filteredVideos.length > 0 ? (
              <div className="slider-container">
                {filteredVideos.map((video) => (
                  <div key={video.id} className="video-card-vertical">
                    <video
                      id={`video-${video.id}`}
                      controls // Додаємо елементи керування (play, pause)
                      width="100%" // Встановлюємо ширину відео для адаптації до контейнера
                      height="auto" // Автоматичне визначення висоти для збереження пропорцій
                      poster={`${BASE_URL}${video.thumbnail}`} // Використовуємо thumbnail для постера
                      onClick={() => handleFullScreen(video.id)} // Відкриваємо відео на весь екран при натисканні
                    >
                      <source src={`${BASE_URL}${video.videoUrl}`} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    <div className="video-info">
                      <h3>{video.videoText}</h3>
                      <p>{video.views} views • {video.time_ago}</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>No videos found</p> // Повідомлення, якщо відео відсутні
            )}
          </div>
        )}
      </main>
    </div>
  );
};

export default Generation;
