import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Input, message, Typography } from 'antd';
import './VideoGenerationPage.css';
import microfonSvg from './img/microfon.svg';
import { useNavigate } from "react-router-dom";
import babyImg from './img/baby.png';
import girafe from './img/girafe.svg';
import VideoGallery from "./VideoGallery";
import BASE_URL from './config';  // Імпортуємо базовий домен з конфігурації

const { Title } = Typography;

const VideoGenerationPage = () => {
    const [transcript, setTranscript] = useState('');
    const [recognition, setRecognition] = useState(null);
    const [isRecognizing, setIsRecognizing] = useState(false);
    const [prompts, setPrompts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            // message.error('You need to log in first.');
            navigate('/login');
            return;
        }
        // Отримання промптів користувача
        axios.get(`${BASE_URL}/api/user/prompts/`, {  // Використовуємо BASE_URL для запиту
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(response => {
            setPrompts(response.data);
        }).catch(error => {
            console.error('Error fetching prompts:', error);
            message.error('Failed to load prompts.');
        });

        if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            const recognitionInstance = new SpeechRecognition();
            recognitionInstance.continuous = true;
            recognitionInstance.interimResults = false;
            recognitionInstance.lang = 'uk-UA';
            setRecognition(recognitionInstance);
        } else {
            message.error('Speech Recognition is not supported in your browser.');
        }
    }, []);

    const startRecognition = () => {
        if (recognition && !isRecognizing) {
            recognition.start();
            setIsRecognizing(true);
            recognition.onresult = (event) => {
                const speechToText = Array.from(event.results)
                    .map(result => result[0].transcript)
                    .join('');
                setTranscript(speechToText);
            };

            recognition.onend = () => {
                setIsRecognizing(false);
            };
        }
    };

    const stopRecognition = () => {
        if (recognition && isRecognizing) {
            recognition.stop();
            setIsRecognizing(false);
        }
    };

    const checkVideoStatus = async (promptId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${BASE_URL}/api/check-video-status/${promptId}/`, {  // Використовуємо BASE_URL для запиту
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data.status === 'ready') {
                const updatedPrompts = await axios.get(`${BASE_URL}/api/user/prompts/`, {  // Використовуємо BASE_URL для запиту
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setPrompts(updatedPrompts.data);
                message.success('Your video is ready!');
            } else {
                setTimeout(() => checkVideoStatus(promptId), 5000);
            }
        } catch (error) {
            console.error('Error checking video status:', error);
            message.error('Error checking video status.');
        }
    };

    const handleSendClick = async () => {
        if (transcript.trim()) {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.post(`${BASE_URL}/api/save-prompt/`, {  // Використовуємо BASE_URL для запиту
                    text: transcript
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.status === 201) {
                    message.success('Prompt saved successfully!');
                    const promptId = response.data.id;
                    checkVideoStatus(promptId);
                }
            } catch (error) {
                console.error('Error saving prompt:', error);
                message.error('Error saving prompt.');
            }
        } else {
            message.error('There is no text to send.');
        }
    };

    return (
        <div className="video-generation-container">
            <div className="search-section">
                <div className={`mic-button ${isRecognizing ? 'active' : ''}`}>
                    <Button
                        shape="circle"
                        icon={<img src={microfonSvg} alt="microfon"/>}
                        size="large"
                        onClick={isRecognizing ? stopRecognition : startRecognition}
                        style={{ backgroundColor: '#ffcc00' }}
                    />
                    <p>{isRecognizing ? 'Stop Speaking' : 'Start Speaking'}</p>
                </div>
                <Input
                    placeholder="Who is Einstein?"
                    value={transcript}
                    onChange={(e) => setTranscript(e.target.value)}
                    className="search-input"
                />
                <Button type="primary" onClick={handleSendClick} className="send-button">
                    Generate Video
                </Button>
            </div>

            <div className="prompt-list">
                <Title level={4}>Your Prompts</Title>
                <VideoGallery videos={prompts} />
            </div>
            <footer className="footer">
                <img className="baby" src={babyImg} alt="girl-illustration"/>
                <p>© MACHAON</p>
                <img className="girafe" src={girafe} alt="girafe-illustration"/>
            </footer>
        </div>
    );
};

export default VideoGenerationPage;
