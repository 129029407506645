import React from 'react';
import { Form, Input, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleOutlined, FacebookFilled } from '@ant-design/icons';
import { useAuth } from '../AuthContext';
import './Login.css';
import logoImage from '../img/mahaon.png'; // Імпортуйте зображення тут

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    console.log(values);
    try {
      await login(values);
      navigate('/home');
    } catch (error) {
      // console.error('Login failed:', error);
    }
  };

  return (
    <div className="login-container">
      <div className="login-form-container">
      <div className="butterfly butterfly-1"></div>
      <div className="butterfly butterfly-2"></div>
        <img src={logoImage} alt="MAHAON" className="logot" />
        <h2>Login</h2>
        <Form
          form={form}
          name="login"
          className="login-form"
          onFinish={onFinish}
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input placeholder="Username" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Link to="/forgot-password" className="forgot-password">
            Forgot Password?
          </Link>

          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-button">
              Sign In
            </Button>
          </Form.Item>
        </Form>

        <div className="social-login">
          <span>or continue with</span>
          <div className="social-buttons">
            <Button icon={<GoogleOutlined />}>Google</Button>
            <Button icon={<FacebookFilled />}>Facebook</Button>
          </div>
        </div>

        <div className="register-link">
          Don't have an account yet? <Link to="/register">Register for free</Link>
        </div>
      </div>

    </div>
  );
};

export default Login;
