// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background-color: #FFFFFF;
}


.AskStyle_generation-container__VGw5b {
    display: flex;
    flex-direction: column;
    height: auto;
    background: linear-gradient(180deg, #ae6a04 0%, #ff9b00 100%)!important;
    color: white;
    font-family: 'Arial', sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/components/ask/AskStyle.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;;AAGA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,uEAAuE;IACvE,YAAY;IACZ,gCAAgC;AACpC","sourcesContent":["body {\n    background-color: #FFFFFF;\n}\n\n\n.generation-container {\n    display: flex;\n    flex-direction: column;\n    height: auto;\n    background: linear-gradient(180deg, #ae6a04 0%, #ff9b00 100%)!important;\n    color: white;\n    font-family: 'Arial', sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"generation-container": `AskStyle_generation-container__VGw5b`
};
export default ___CSS_LOADER_EXPORT___;
