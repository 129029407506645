import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import axios from 'axios';
import isEqual from 'lodash/isEqual';
import BASE_URL from './config';  // Імпортуємо домен з конфігурації

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow-y: auto;
  gap: 20px;
  padding: 20px;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: ${({ isHovered }) => (isHovered ? '420px' : '320px')};
  height: ${({ isHovered }) => (isHovered ? '240px' : '180px')};
  margin: 0 auto;
  transition: all 0.4s ease-in-out;
  box-shadow: ${({ isHovered }) => isHovered ? '0 10px 25px rgba(0, 0, 0, 0.3)' : '0 4px 10px rgba(0, 0, 0, 0.1)'};
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const BlackScreen = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1rem;
`;

const VideoTitle = styled.h3`
  font-size: 1rem;
  text-align: center;
  margin-top: 10px;
  color: #333;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`;

const VideoGallery = () => {
  const [videos, setVideos] = useState([]);
  const [previousVideos, setPreviousVideos] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${BASE_URL}/api/user/prompts/`, {  // Використовуємо BASE_URL з конфігурації
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const currentVideos = response.data;

        if (!isEqual(currentVideos, previousVideos)) {
          setVideos(currentVideos);
          setPreviousVideos(currentVideos);
        }
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    const intervalId = setInterval(fetchVideos, 10000);  // Змінили інтервал на 10 секунд

    return () => clearInterval(intervalId);
  }, [previousVideos]);

  return (
<GalleryContainer>
  {videos.map((video, index) => (
    <div key={index}>
      <VideoWrapper
        isHovered={hoveredIndex === index}
        onMouseEnter={() => setHoveredIndex(index)}
        onMouseLeave={() => setHoveredIndex(null)}
      >
        {video.status === 'processed' ? (
          hoveredIndex === index ? (
            <ReactPlayer
              url={video.video.startsWith('http') ? video.video : `${BASE_URL}${video.video}`}  // Додаємо BASE_URL тільки якщо шлях не є абсолютним
              width="100%"
              height="100%"
              controls={true}
              playing={hoveredIndex === index}
            />
          ) : (
            <Thumbnail src={video.thumbnail_url || `${BASE_URL}${video.video}#t=0.1`} alt={video.text} />
          )
        ) : (
          <BlackScreen>
            Video is not ready
          </BlackScreen>
        )}
      </VideoWrapper>
      <VideoTitle>{video.text}</VideoTitle>
    </div>
  ))}
</GalleryContainer>
  );
};

export default VideoGallery;
